import { Space, Spin } from 'antd';

import styles from '../styles/Home.module.css';

const Loading = () => (
    <div className={styles.container}>
      <main className={styles.main}>
        <Space size="middle" direction="vertical" align="center">
          <Spin />
        </Space>
      </main>
    </div>
)

export default Loading;
